/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IOrderbook, IOrderbookInterface } from "../IOrderbook";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
    ],
    name: "cancelOrders",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
    ],
    name: "claimOrders",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_orderId",
        type: "uint256",
      },
    ],
    name: "exists",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "_amounts",
        type: "uint256[]",
      },
    ],
    name: "fillOrders",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_orderId",
        type: "uint256",
      },
    ],
    name: "getOrder",
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "contentAddress",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
              },
            ],
            internalType: "struct LibOrder.AssetData",
            name: "asset",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amountOrdered",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amountFilled",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isBuyOrder",
            type: "bool",
          },
          {
            internalType: "enum LibOrder.OrderState",
            name: "state",
            type: "uint8",
          },
        ],
        internalType: "struct LibOrder.Order",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "amountToFill",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxSpend",
        type: "uint256",
      },
    ],
    name: "getOrderAmounts",
    outputs: [
      {
        internalType: "uint256[]",
        name: "orderAmounts",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "amountFilled",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "_amounts",
        type: "uint256[]",
      },
    ],
    name: "getPaymentTotals",
    outputs: [
      {
        internalType: "uint256",
        name: "volume",
        type: "uint256",
      },
      {
        internalType: "uint256[]",
        name: "amountPerOrder",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ordersLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "address",
                name: "contentAddress",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
              },
            ],
            internalType: "struct LibOrder.AssetData",
            name: "asset",
            type: "tuple",
          },
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "price",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "isBuyOrder",
            type: "bool",
          },
        ],
        internalType: "struct LibOrder.OrderInput",
        name: "_order",
        type: "tuple",
      },
    ],
    name: "placeOrder",
    outputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
      {
        internalType: "bool",
        name: "_isBuyOrder",
        type: "bool",
      },
    ],
    name: "verifyAllOrdersData",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
    ],
    name: "verifyOrderOwners",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
    ],
    name: "verifyOrdersExist",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_orderIds",
        type: "uint256[]",
      },
    ],
    name: "verifyOrdersReady",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IOrderbook__factory {
  static readonly abi = _abi;
  static createInterface(): IOrderbookInterface {
    return new utils.Interface(_abi) as IOrderbookInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IOrderbook {
    return new Contract(address, _abi, signerOrProvider) as IOrderbook;
  }
}
