/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { LibAsset, LibAssetInterface } from "../LibAsset";

const _abi = [
  {
    inputs: [],
    name: "MINT_DATA_TYPEHASH",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x60dc610052600b82828239805160001a607314610045577f4e487b7100000000000000000000000000000000000000000000000000000000600052600060045260246000fd5b30600052607381538281f3fe730000000000000000000000000000000000000000301460806040526004361060335760003560e01c806337ca8c03146038575b600080fd5b603e6052565b604051604991906083565b60405180910390f35b7fd7a98481715a2eae452e15aa785ae58739157502bf58d78ab043ad8516c116e281565b607d81609c565b82525050565b6000602082019050609660008301846076565b92915050565b600081905091905056fea26469706673582212206cf1ed6253c0477b569a89c7e31c1c497d78f06b64846a08f9bd9dafcdf1b03c64736f6c63430008030033";

export class LibAsset__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<LibAsset> {
    return super.deploy(overrides || {}) as Promise<LibAsset>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): LibAsset {
    return super.attach(address) as LibAsset;
  }
  connect(signer: Signer): LibAsset__factory {
    return super.connect(signer) as LibAsset__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): LibAssetInterface {
    return new utils.Interface(_abi) as LibAssetInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibAsset {
    return new Contract(address, _abi, signerOrProvider) as LibAsset;
  }
}
