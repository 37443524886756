/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  LibContractHash,
  LibContractHashInterface,
} from "../LibContractHash";

const _abi = [
  {
    inputs: [],
    name: "CONTRACT_CONTENT_FACTORY",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_DAI_TOKEN",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_ERC20_ESCROW",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_EXCHANGE",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_EXCHANGE_FEE_ESCROW",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_EXECUTION_MANAGER",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_LIQUIDITY_MINING",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_NFT_ESCROW",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_ORDERBOOK",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_RAWR_TOKEN",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_ROYALTY_MANAGER",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "CONTRACT_STAKING",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x61033b610053600b82828239805160001a607314610046577f4e487b7100000000000000000000000000000000000000000000000000000000600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600436106100be5760003560e01c8063af39bf841161007b578063af39bf8414610177578063b582684014610195578063caca2452146101b3578063cb9d5b49146101d1578063e3e062b7146101ef578063ef6adf6d1461020d576100be565b806302d12eb2146100c3578063091ed1a7146100e15780634104312b146100ff57806383867fea1461011d578063a0e3a10e1461013b578063a38e614c14610159575b600080fd5b6100cb61022b565b6040516100d891906102be565b60405180910390f35b6100e9610236565b6040516100f691906102be565b60405180910390f35b610107610241565b60405161011491906102be565b60405180910390f35b61012561024c565b60405161013291906102be565b60405180910390f35b610143610257565b60405161015091906102be565b60405180910390f35b610161610262565b60405161016e91906102be565b60405180910390f35b61017f61026d565b60405161018c91906102be565b60405180910390f35b61019d610278565b6040516101aa91906102be565b60405180910390f35b6101bb610283565b6040516101c891906102be565b60405180910390f35b6101d961028e565b6040516101e691906102be565b60405180910390f35b6101f7610299565b60405161020491906102be565b60405180910390f35b6102156102a4565b60405161022291906102be565b60405180910390f35b631b48faca60e01b81565b634170d96f60e01b81565b63d9ff761860e01b81565b6387d4498b60e01b81565b63eef6410360e01b81565b633d13c04360e01b81565b632c7e992e60e01b81565b63018869a960e01b81565b63db337f7d60e01b81565b6329a264aa60e01b81565b637f17083660e01b81565b63385742b960e01b81565b6102b8816102d9565b82525050565b60006020820190506102d360008301846102af565b92915050565b60007fffffffff000000000000000000000000000000000000000000000000000000008216905091905056fea2646970667358221220838cde7d6b1f5c8c1a6065ebcad51a5d729f18c2df4a9c69069f4ce9de79fe8064736f6c63430008030033";

export class LibContractHash__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<LibContractHash> {
    return super.deploy(overrides || {}) as Promise<LibContractHash>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): LibContractHash {
    return super.attach(address) as LibContractHash;
  }
  connect(signer: Signer): LibContractHash__factory {
    return super.connect(signer) as LibContractHash__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): LibContractHashInterface {
    return new utils.Interface(_abi) as LibContractHashInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): LibContractHash {
    return new Contract(address, _abi, signerOrProvider) as LibContractHash;
  }
}
